<template>
  <div>
    <el-button class="btn" size="mini" @click="openDialog('新建角色')">新增角色</el-button>
    <div class="container">
      <el-card class="left">
        <div slot="header" class="fwb mb8">管理角色</div>
        <div
          v-for="(item, index) in roleList"
          :key="index"
          class="left-item fz14"
          :class="role.id === item.id ? 'select' : ''"
          @click="getDetail(item.id)"
        >
          <div class="lh28">{{ item.title }}</div>
          <div v-if="role.id === item.id && item.id !== 1 && item.id !== 2">
            <el-button type="text" size="mini" @click="openDialog('编辑角色', item)">编 辑</el-button>
            <el-button type="text" size="mini" @click="openDialog('删除角色')">删 除</el-button>
          </div>
        </div>
      </el-card>
      <el-card class="right" v-loading="loading">
        <div slot="header" class="card-header">
          <div class="fwb">{{ role.title }} 权限</div>
          <el-button
            v-if="role.id !== 1 && role.id !== 2"
            type="primary"
            size="mini"
            class="permission"
            @click="openDialog('保存权限')"
          >保存权限</el-button>
        </div>
        <el-scrollbar class="scroll">
          <el-tree
            ref="treeOne"
            :data="menuList"
            :props="props"
            :indent="0"
            :expand-on-click-node="false"
            :default-checked-keys="menusOne"
            show-checkbox
            default-expand-all
            node-key="id"
            @check="handleOneChange"
          >
            <div slot-scope="{ node, data }">
              <span v-if="data.level === 1" class="fwb fz24">{{node.label}}</span>
              <span v-else-if="data.level === 2" class="fz16">{{node.label}}</span>
              <span
                v-else-if="data.level === 3 && data.children && data.children.length > 0"
                class="fwb fz16"
              >{{ node.label }}</span>
              <span
                v-else-if=" data.level === 4 || (data.level === 3 && !data.children)"
                class="fz14 c999"
              >{{ node.label }}</span>
            </div>
          </el-tree>
        </el-scrollbar>
      </el-card>
    </div>
    <!--角色编辑-->
    <el-dialog
      :title="`${title}角色`"
      :visible.sync="showRole"
      :close-on-click-modal="false"
      width="600px"
      top="40px"
      class="add"
    >
      <el-form ref="role" label-position="top" size="mini" :model="role" :rules="rules">
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <el-form-item label="角色名称" prop="title">
              <el-input v-model="role.title" class="input" show-word-limit maxlength="20" />
            </el-form-item>
            <div v-if="!userInfo.shop_id" class="mb10 c666 fwb">数据权限</div>

            <el-form-item label="市场" v-if="!userInfo.shop_id">
              <el-radio-group v-model="role.is_market" @change="changeIsMarket">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">部分</el-radio>
              </el-radio-group>
              <el-cascader
                v-if="role.is_market == 1"
                class="input"
                :value="market_view"
                :options="market"
                :props="branch"
                clearable
                @change="changeMarket"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="clickSubmit('role')">确 定</el-button>
        <el-button size="small" @click="showRole = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// role === 1 超级管理员角色
// role === 2 默认角色
// 不能编辑和删除
import {
  roleList,
  roleDetail,
  menuList,
  updateRole,
  addRole,
  deleteRole,
  departmentList
} from "@/api/permission";
// import { marketList } from "@/api/shop";
import { mapGetters } from "vuex";
import { getStore } from "@/utils/store";
class Role {
  title = "";
  is_market = 0;
  market = "";
}
class Rules {
  title = [{ required: true, message: "请输入角色名称", trigger: "blur" }];
}
export default {
  name: "Role",
  data() {
    return {
      loading: false,
      props: {
        label: "title",
        children: "children"
      }, // 菜单树状图
      type: [
        { id: 1, name: "本人" },
        { id: 2, name: "本人及下属" },
        { id: 3, name: "本部门" },
        { id: 4, name: "本部门及下属部门" },
        { id: 5, name: "全部" }
      ],
      role: new Role(), // 当前角色
      rules: new Rules(), // 当前角色
      menusOne: [], // 当前所选
      roleList: [], // 角色列表
      menuList: [], // 菜单列表
      market: [], // 市场列表
      branch: { multiple: true },
      showRole: false, // 角色弹窗
      title: "新增",
      market_view: []
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOption();
  },
  methods: {
    // 获取列表
    async getDetail(role_id) {
      if (!this.loading) {
        this.loading = true;
      }
      const {
        dataAuth,
        front_menus,
        title,
        id,
        market_view,
        market
      } = await roleDetail({
        id: role_id
      });
      this.loading = false;
      this.role = { ...dataAuth, title, id };
      if (market) {
        this.role.is_market = 1;
        this.market_view = market_view;
      } else {
        this.role.is_market = 0;
      }
      this.$nextTick(() => {
        this.$refs.treeOne && this.$refs.treeOne.setCheckedKeys([]);
        this.menusOne = Array.isArray(front_menus) ? front_menus : [];
      });
    },
    // 获取选项
    async getOption() {
      this.loading = true;
      this.roleList = await roleList();
      let res = await departmentList({ page: 1, limit: 100000 });

      let tempList1 = [];
      tempList1 = res.data.list.map(item => {
        let tempList2 = [];
        item.shop.forEach(val => {
          let obg = {};
          obg["value"] = val.shopId;
          obg["label"] = val.shopName;
          tempList2.push(obg);
        });
        return Object.assign(
          {},
          { value: item.id, label: item.name, children: tempList2 }
        );
      });

      this.market = tempList1;

      this.menuList = await menuList({ type: "tree" });
      this.getDetail(this.roleList[0].id);
      this.$nextTick(() => {
        this.setInlineBlock("treeOne");
      });
    },
    // 改变市场是否全部
    changeIsMarket(e) {
      e == 0 ? (this.role.market = "") : "";
    },
    // 改变部门
    changeMarket(e) {
      let shopId = [];
      e.forEach(item => {
        shopId.push(item[1]);
      });
      this.role.market = shopId;
      this.role.market_view = e;
      this.market_view = e;
      // console.log(e)
    },
    // 树状图最底层添加样式
    setInlineBlock(formName) {
      this.$refs[formName] &&
        this.$refs[formName].$el
          .querySelectorAll(".el-tree-node__children:empty")
          .forEach(node => {
            node.parentNode.style.display = "inline-block";
          });
    },
    // 点击节点
    handleOneChange(data, checked) {
      let selected = [];
      checked.checkedNodes.forEach(nodeItem => {
        if (!nodeItem.children) selected.push(nodeItem.id);
      });
      this.menusOne = selected;
    },
    // 操作
    async openDialog(option, item) {
      switch (option) {
        case "新建角色":
          this.role = new Role();
          this.market_view = [];
          this.showRole = true;
          break;
        case "保存权限":
          this.editRoleAuth();
          break;
        case "编辑角色":
          const data = await roleDetail({
            id: item.id
          });
          this.title = "编辑";
          this.showRole = true;
          break;
        case "删除角色":
          this.deleteRole();
          break;
      }
    },
    // 删除
    deleteRole() {
      this.$confirm(
        `此操作将删除角色【${this.role.title}】，是否继续？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(async () => {
          try {
            await deleteRole({ id: this.role.id });
            this.$message({
              type: "success",
              message: "操作成功"
            });
            this.getOption();
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {});
    },
    // 编辑角色权限
    async editRoleAuth() {
      this.loading = true;
      await updateRole({ id: this.role.id, menus: [...this.menusOne] });
      this.loading = false;
      this.$message({
        type: "success",
        message: "操作成功"
      });
      this.getDetail(this.role.id);
    },
    // 确定
    clickSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // console.log(this.role);
          if (this.role.is_market == 1 && !this.role.market.length) {
            this.$message({
              type: "warning",
              message: "请选择市场"
            });
          } else {
            this.editRoleInfo();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑，新增角色信息
    async editRoleInfo() {
      this.role.id
        ? await updateRole(this.role) // 角色权限编辑
        : await addRole(this.role); //  新建角色
      this.$message({
        type: "success",
        message: "操作成功"
      });
      this.showRole = false;
      this.getOption();
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  .title {
    color: #606266;
    font-size: 15px;
    font-weight: bold;
    img {
      width: 26px;
      height: 26px;
    }
  }
  .search {
    display: flex;
    box-sizing: border-box;
  }
}
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .left {
    width: 300px;
    height: 100%;
    overflow-y: hidden;
    .left-item {
      padding: 12px 20px;
      cursor: pointer;
      &:hover {
        background: #f6f8fa;
      }
      &.select {
        background: #f6f8fa;
        border-left: 4px solid #33bea7;
        padding-left: 16px;
      }
    }
    ::v-deep.el-card__body {
      padding: 0;
    }
  }
  .right {
    flex: 1;
    height: 100%;
    overflow-y: hidden;
    position: relative;
    .card-header {
      display: flex;
      align-items: center;
      .permission {
        margin-left: auto;
      }
    }
    .scroll {
      height: 66vh;
      .el-tree {
        ::v-deep.el-tree-node {
          white-space: inherit;
          margin-bottom: 5px;
          .el-tree-node__content {
            margin-bottom: 15px;
            width: 200px;
            .el-tree-node__expand-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}

.btn {
  @include successBtn(100px, 28px);
  // line-height: 4px;
  margin-bottom: 6px;
}
.add {
  // background: #f5f6f9;
  .input {
    width: 100%;
  }
  ::v-deep.el-dialog {
    // height: calc(100vh - 200px);
    overflow-y: auto;
  }
  ::v-deep.el-dialog__body {
    padding: 10px 20px;
  }
}
::v-deep.el-form--label-top {
  .el-form-item__label {
    padding: 0;
  }
}
</style>
